import React, { useState } from 'react';

import { useTheme } from '@material-ui/core/styles';
import { Button, createStyles, Grid, IconButton, makeStyles, TextField, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: 'white',
      height: '100vh'
    },
    itemContainer: {
      background: 'linear-gradient(90deg, #2CA9FB 0%, #50CFFF 100%)',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: 20,
      justifyContent: 'center'
    },
    textTitle: {
      color: 'white',
      fontWeight: 600,
      fontSize: 25,
      marginTop: 30
    },
    text: {
      color: 'white',
      fontWeight: 500,
      fontSize: 16,
      margin: 6,
      textAlign: 'center',
    },
    logoContainer: {
      height: 200,
      width: 200,
      marginTop: 20,
      marginBottom: 20,
      borderRadius: 15
    },
    iconsContainer: {
      display: 'flex',
      marginTop: 15,
      marginBottom: 15
    },
    logoConfirm: {
      height: 50,
      width: 50,
      marginBottom: 15
    },
    iconButton: {
      padding: 3,
      borderRadius: 15,
      margin: 10,
      border: '2px solid transparent',
      '&:hover': {
        '&#android': {
          border: '2px solid #29D379'
        },
        '&#apple': {
          border: '2px solid black'
        },
      }
    },
    iconImage: {
      height: 50,
      width: 50,
    },
    form: {
      display: 'flex',
      flexDirection: 'column'
    },
    textField: {
      marginTop: 15,
      marginLeft: 5,
      marginRight: 5,
      '& input': {
        color: 'white',
        borderColor: 'white',
        borderRadius:23
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius:23,
        borderColor: 'white',
        '& .Mui-error': {
          borderColor: '#d32f2f'
        },
      },
      '& .MuiFormLabel-root': {
        color: 'white',
        fontSize: 14,
      },
    },
    button: {
      background: 'white',
      color: '#2CA9FB',
      marginTop: 15,
      textTransform: 'capitalize',
      fontWeight: 600,
      border: '1px solid white',
      margin: 15,
      borderRadius: 23,
      '&:hover': {
        color: 'white',
      },
      
    }
  })
);

function App() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [showForm, setShowForm] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [form, setForm] = useState({
    name: '',
    lastname: '',
    email: '',
    phone: '',
  });

  const sendData = () => {
    setSubmitted(true);
    setForm({
      name: '',
      lastname: '',
      email: '',
      phone: '',
    });
  };

  return (
    <div style={{ flex: 1 }}>
      <Grid container className={classes.root} justifyContent='center'>
        <Grid item xs={11} sm={10} md={9} lg={8} xl={7} className={classes.itemContainer}>
          <div className={classes.textTitle}>
            Welcome to iMotiv8
          </div>
          <div className={classes.text} style={{ marginTop: 5 }}>
            beta version
          </div>
          <img src='/logo_beta.png' alt='logo' className={classes.logoContainer} />
          {submitted &&
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <img src='/check.png' alt='logo' className={classes.logoConfirm} />
              <p className={classes.text}>
                In a moment we will send to your email the access <br /> to download the application.
              </p>
              <img src='/apple.png' alt='logo' className={classes.iconImage} />
            </div>
          }
          <p className={classes.text}>
            Select the operating system of your mobile device to <br /> continue with the download.
          </p>
          <div className={classes.iconsContainer}>
            <IconButton
              className={classes.iconButton}
              id='android'
              href='https://imotiv8-kodia.s3.amazonaws.com/android/app-release.apk'
              target='_blank'
              // onClick={() => {
              //   setShowForm(false);
              //   setSubmitted(false);
              // }}
            >
              <img src='/android.png' alt='logo' className={classes.iconImage} />
            </IconButton>
            <IconButton
              className={classes.iconButton}
              id='apple'
              href='https://apps.apple.com/us/app/imotiv8-kodia/id6444133457'
              target='_blank'
              // onClick={() => {
              //   setShowForm(true);
              //   setSubmitted(false);
              // }}
            >
              <img src='/apple.png' alt='logo' className={classes.iconImage} />
            </IconButton>
          </div>
          {showForm &&
            <div className={classes.form}>
              <div style={{ display: 'flex', flexDirection: matches ? 'row' : 'column'}}>
                <TextField
                  label="Name"
                  id="outlined-size-small"
                  variant="outlined"
                  size="small"
                  value={form.name}
                  className={classes.textField}
                  />
                <TextField
                  label="Last name"
                  value={form.lastname}
                  className={classes.textField}
                  id="outlined-size-small"
                  variant="outlined"
                  size="small"
                  />
              </div>
              <div style={{ display: 'flex', flexDirection: matches ? 'row' : 'column'}}>
                <TextField
                  value={form.email}
                  className={classes.textField}
                  label="Email"
                  id="outlined-size-small"
                  variant="outlined"
                  size="small"
                  />
                <TextField
                  value={form.phone}
                  className={classes.textField}
                  label="Phone number"
                  id="outlined-size-small"
                  variant="outlined"
                  size="small"
                />
              </div>
              <Button className={classes.button} onClick={sendData}>
                Send
              </Button>
            </div>
          }
        </Grid>
        <Grid item xs={11} sm={10} md={9} lg={8} xl={7} className={classes.itemContainer}>
          <p className={classes.text}>
            Developed by
          </p>
          <a href={'https://kodia.us/'} target='_blank' rel="noreferrer">
            <img src='/image.png' alt='logo' />
          </a>
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
